exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-home-server-js": () => import("./../../../src/pages/home-server.js" /* webpackChunkName: "component---src-pages-home-server-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

